import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
// import { PageLoader } from './page-loader';
import Login from '../pages/Login';

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Login />,
  });

  return <Component />;
};

// material
import {
  Box,
  Button,
  Card,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  applySortFilter,
  formatPullRequestsData,
  formatReviewsData,
  getComparator,
} from "../utils/sortData";
import { fToNow, upperFirst } from "./../utils/formatTime";
import { useEffect, useState } from "react";

import Page from "../components/Page";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead } from "../sections/user";
import { useLocation } from "react-router-dom";
import { fetchCustom } from "../utils/fetch";
import { useAuth0 } from "@auth0/auth0-react";

const JIRA_LINK = "https://identivegroup.atlassian.net/browse/";
const TABLE_HEAD_PR = [
  { id: "title", label: "Title", alignRight: false, sort: false },
  { id: "issue", label: "Jira", alignRight: false, sort: false },
  {
    id: "repository_name",
    label: "Repository",
    alignRight: false,
    sort: false,
  },
  { id: "data_type", label: "Data type", alignRight: false, sort: false },
  { id: "state", label: "Status", alignRight: false, sort: false },
  { id: "updated_at", label: "Date", alignRight: false, sort: true },
  { id: "", label: "Size", alignRight: false, sort: false },
];
const TABLE_HEAD_COMMIT = [
  { id: "message", label: "Message", alignRight: false, sort: false },
  { id: "pull_title", label: "Pull Request", alignRight: false, sort: false },
  {
    id: "repository_name",
    label: "Repository",
    alignRight: false,
    sort: false,
  },
  { id: "data_type", label: "Data type", alignRight: false, sort: false },
  // { id: 'verified', label: 'Verified', alignRight: false, sort: false },
  { id: "commit_date", label: "Commit Date", alignRight: false, sort: true },
  // { id: '', label: 'Size', alignRight: false, sort: false },
];

const TABLE_HEAD_REVIEWS = [
  { id: "body", label: "Title", alignRight: false, sort: false },
  { id: "pull_title", label: "Pull Request", alignRight: false, sort: false },
  {
    id: "repository_name",
    label: "Repository",
    alignRight: false,
    sort: false,
  },
  { id: "data_type", label: "Data type", alignRight: false, sort: false },
  { id: "state", label: "Status", alignRight: false, sort: false },
  { id: "submitted_at", label: "Date", alignRight: false, sort: true },
];

function subtotal(items) {
  let result = "";
  let sum1 = 0;
  let sum2 = 0;
  items.forEach((item) => {
    sum1 += item.additions;
    sum2 += item.deletions;
  });
  return `${result}+${sum1},-${sum2}`;
}

export default function PRUser(prop) {
  const { getAccessTokenSilently } = useAuth0();
  const { state } = useLocation();
  const { id, login, name } = state; // Read values passed on state

  // PRs
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updated_at");
  // Commits
  const [orderC, setOrderC] = useState("desc");
  const [orderCBy, setOrderCBy] = useState("commit_date");
  // Reviews
  const [orderR, setOrderR] = useState("desc");
  const [orderRBy, setOrderRBy] = useState("submitted_at");

  let [prs, setPrs] = useState([]);
  const [totalPRs, setTotalPRs] = useState(0);
  let [lstCommists, setCommits] = useState([]);
  const [totalCommits, setTotalCommits] = useState(0);
  let [prReviews, setPRReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);

  //make request api to get and update data
  //Debug with mock data
  // useEffect(() => {
  //     let mounted = true;
  //     setPrs(PRs);
  //     setPRReviews(PRReviews);

  //     return () => mounted = false;
  // }, [])
  //END debug with mock data

  let filteredPRs = [];
  let filteredReviews = [];

  // GET with fetch API
  // https://www.freecodecamp.org/news/how-to-consume-rest-apis-in-react/
  useEffect(() => {
    const fetchPulls = async () => {
      //id = 5907818
      await Promise.all([
        (async () => {
          let urlPull = `${process.env.REACT_APP_API_SERVER_URL}/pulls?page=1&per_page=10000&user_id=${id}`;
          console.log("urlPulls: ", urlPull);
          const pulls = await fetchCustom(urlPull, getAccessTokenSilently);
          if (pulls) {
            setPrs(formatPullRequestsData(pulls.data, prop.gerrit));
            setTotalPRs(pulls.total);
          }
        })(),
        (async () => {
          let urlCommits = `${process.env.REACT_APP_API_SERVER_URL}/commits?page=1&per_page=10000&user_id=${id}`;
          console.log("urlPulls: ", urlCommits);
          const commits = await fetchCustom(urlCommits, getAccessTokenSilently);
          if (commits) {
            setCommits(formatPullRequestsData(commits.data, prop.gerrit));
            setTotalCommits(commits.total);
          }
        })(),
        (async () => {
          let urlReview = `${process.env.REACT_APP_API_SERVER_URL}/reviews?page=1&per_page=10000&user_review_id=${id}`;
          const reviews = await fetchCustom(urlReview, getAccessTokenSilently);
          if (reviews) {
            setPRReviews(formatReviewsData(reviews.data, prop.gerrit));
            setTotalReviews(reviews.total);
          }
        })(),
      ]);
    };
    fetchPulls();
    return () => {};
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (property) {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  const handleRequestSortReview = (event, property) => {
    const isAsc = orderRBy === property && orderR === "asc";
    if (property) {
      setOrderR(isAsc ? "desc" : "asc");
      setOrderRBy(property);
    }
  };

  const handleCommitSort = (event, property) => {
    const isAsc = orderCBy === property && orderC === "asc";
    if (property) {
      setOrderC(isAsc ? "desc" : "asc");
      setOrderCBy(property);
    }
  };

  filteredPRs = applySortFilter(prs, getComparator(order, orderBy), "");
  filteredReviews = applySortFilter(
    prReviews,
    getComparator(orderR, orderRBy),
    ""
  );

  lstCommists = applySortFilter(
    lstCommists,
    getComparator(orderC, orderCBy),
    ""
  );
  prs = filteredPRs;
  prReviews = filteredReviews;

  const isPrsNotFound = filteredPRs.length === 0;
  const isReviewsNotFound = filteredReviews.length === 0;

  const changesSubtotal = subtotal(filteredPRs);

  return (
    <Page title="User">
      <Container sx={{ padding: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h2" gutterBottom>
            User: {name || login}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h4" gutterBottom>
            Pull Requests
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="subtitle1" gutterBottom>
            Total: {totalPRs}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {!isPrsNotFound && (
              <Typography variant="subtitle1" gutterBottom>
                Size: {changesSubtotal}
              </Typography>
            )}
          </Typography>
        </Stack>
        <Card>
          <TableContainer sx={{ minWidth: 800, minHeight: 300 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD_PR}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {filteredPRs.map((row) => {
                  const {
                    id,
                    title,
                    state,
                    updated_at,
                    additions,
                    deletions,
                    html_url,
                    issue,
                    data_type,
                    repo,
                  } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                      <TableCell padding="2">
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={html_url}
                          variant="body2"
                        >
                          {title}
                        </Link>
                      </TableCell>
                      <TableCell padding="2">
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`${JIRA_LINK}${issue}`}
                          variant="body2"
                          noWrap={true}
                        >
                          {issue}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={repo.html_url}
                          variant="body2"
                        >
                          {repo.name}
                        </Link>
                      </TableCell>
                      <TableCell>{upperFirst(data_type)}</TableCell>
                      <TableCell>{state.toUpperCase()}</TableCell>
                      <TableCell>{fToNow(updated_at)}</TableCell>
                      <TableCell align="left">
                        +{additions},-{deletions}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isPrsNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery="" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>

        {/* Commits Stack */}
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
          >
            <Typography variant="h4" gutterBottom>
              Commits
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
          >
            <Typography variant="subtitle1" gutterBottom>
              Total: {totalCommits}
            </Typography>
          </Stack>

          <Card>
            <TableContainer sx={{ minWidth: 800, minHeight: 300 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <UserListHead
                  order={orderC}
                  orderBy={orderCBy}
                  headLabel={TABLE_HEAD_COMMIT}
                  onRequestSort={handleCommitSort}
                />

                <TableBody>
                  {lstCommists.map((row) => {
                    const {
                      id,
                      message,
                      html_url,
                      data_type,
                      repo,
                      commit_date,
                      pull,
                    } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell scope="row" padding="2">
                          <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href={html_url}
                            variant="body2"
                          >
                            {message}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href={pull.html_url}
                            variant="body2"
                          >
                            {pull.title}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href={repo.html_url}
                            variant="body2"
                          >
                            {repo.name}
                          </Link>
                        </TableCell>
                        <TableCell>{upperFirst(data_type)}</TableCell>
                        {/* <TableCell>Verified</TableCell> */}
                        <TableCell>{fToNow(commit_date)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isPrsNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery="" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Card>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h4" gutterBottom>
            Reviews
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="subtitle1" gutterBottom>
            Total: {totalReviews}
          </Typography>
        </Stack>
        <Card>
          <TableContainer sx={{ minWidth: 800, minHeight: 200 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <UserListHead
                order={orderR}
                orderBy={orderRBy}
                headLabel={TABLE_HEAD_REVIEWS}
                onRequestSort={handleRequestSortReview}
              />

              <TableBody>
                {filteredReviews.map((row) => {
                  const {
                    id,
                    state,
                    pull,
                    submitted_at,
                    html_url,
                    body,
                    data_type,
                  } = row;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="2">
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={html_url ? html_url : row.pull_html_url}
                          variant="body2"
                        >
                          {body}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={pull.html_url}
                          variant="body2"
                        >
                          {pull.title}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={row.repository_url}
                          variant="body2"
                        >
                          {row.repository_name}
                        </Link>
                      </TableCell>

                      <TableCell>{upperFirst(data_type)}</TableCell>
                      <TableCell>{state}</TableCell>
                      <TableCell style={{ minWidth: 100 }}>
                        {fToNow(submitted_at)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isReviewsNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery="" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>

        <Button
          style={{ marginTop: 10 }}
          variant="contained"
          href={
            prop.gerrit ? "/dashboard/gerrit/users" : "/dashboard/github/users"
          }
        >
          Back
        </Button>
      </Container>
    </Page>
  );
}

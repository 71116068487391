import { Navigate, useRoutes } from "react-router-dom";

import Layout from "./layouts/dashboard";
import Login from "./pages/Login";
import LoginCallback from "./pages/LoginCallback";
import PRUser from "./pages/PRUser";
import Repos from "./pages/Repos";
import User from "./pages/User";
import { AuthenticationGuard } from "./components/authentication-guard";
import DashBoard from "./pages/Dashboard";

export default function Router() {
  return useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/callback",
      element: <LoginCallback />,
    },
    {
      path: "/dashboard",
      element: <AuthenticationGuard component={Layout} />,
      children: [
        {
          path: "home",
          element: <DashBoard />,
        },
        {
          path: "users",
          element: <User />,
        },
        {
          path: "repositories",
          element: <Repos />,
        },
        { path: "users/pullrequest", element: <PRUser /> },
      ],
    },
    { path: "*", element: <Navigate to="/login" replace /> },
  ]);
}

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Box } from '@mui/material';
import { Navigate } from 'react-router-dom';

export default function Login() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  // TODO check token expired
  if (isAuthenticated && localStorage.getItem('token')) {
    return <Navigate to="/dashboard/users" />;
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      // style={{ backgroundColor: 'rgb(168 0 29)' }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          onClick={() => loginWithRedirect()}
        >
          Log In
        </Button>
      </Box>
    </Container>
  );
}

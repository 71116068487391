import { Alert, Drawer, Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { navConfig } from "./NavConfig";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavSection from "../../components/NavSection";
import PropTypes from "prop-types";
// components
import Scrollbar from "../../components/Scrollbar";
import Typography from "@mui/material/Typography";
// material
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
// hooks
import useResponsive from "../../hooks/useResponsive";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Iconify from "../../components/Iconify";
import Avatar from "@mui/material/Avatar";
import { useAuth0 } from "@auth0/auth0-react";
import Tooltip from "@mui/material/Tooltip";
import { TOKEN_KEY } from "../../utils/constant";
import { fetchCustom } from "../../utils/fetch";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const settings = ["Sync", "Logout"];

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

function MenuItems() {
  return (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <MenuItem>
        <ListItemText disableTypography />
      </MenuItem>
      <NavSection navConfig={navConfig} />
    </Scrollbar>
  );
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const [mobileOpen, setMobileOpen] = useState(!isDesktop);
  const [open, setOpenSnackbars] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClose = () => {
    setOpenSnackbars(false);
  };
  const callSync = async () => {
    const resp = await fetchCustom(
      `${process.env.REACT_APP_API_SERVER_URL}/syncAll`,
      getAccessTokenSilently,
      { method: "post" }
    );
    if (resp) {
      setOpenSnackbars(true);
    }
  };

  const handleMenuClick = (value) => {
    handleCloseUserMenu();
    if (value === settings[0]) {
      callSync();
    } else if (value === settings[1]) {
      localStorage.removeItem(TOKEN_KEY);
      logout();
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let style = {
    variant: "temporary",
    open: mobileOpen,
    onClose: handleDrawerToggle,
    ModalProps: {
      keepMounted: true,
    },
    sx: {
      display: { xs: "block", lg: "none" },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: DRAWER_WIDTH,
      },
    },
  };
  if (isDesktop) {
    style = {
      open: true,
      variant: "permanent",
      sx: {
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: DRAWER_WIDTH,
        },
      },
    };
  }

  return (
    <RootStyle>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{ backgroundColor: "#ce202f" }}
      >
        <ToolbarStyle>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Log Work
          </Typography>
          <div>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} src={user.picture} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleMenuClick(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </ToolbarStyle>
      </AppBar>
      <Drawer {...style}>
        <ToolbarStyle />
        <MenuItems />
      </Drawer>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Success!
        </Alert>
      </Snackbar>
    </RootStyle>
  );
}

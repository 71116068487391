import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { TOKEN_KEY } from "../utils/constant";

export default function LoginCallback() {
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    error,
    logout,
  } = useAuth0();

  const logouRedirect = `${process.env.REACT_APP_API_SERVER_URL}/login`;

  useEffect(() => {
    const getUserMetadata = async () => {
      if (error) {
        console.error("error", error);
        localStorage.setItem("error", JSON.stringify(error));
        logout({ returnTo: logouRedirect });
      }
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        localStorage.setItem(TOKEN_KEY, accessToken);
      } catch (e) {
        console.log(e.message);
        localStorage.setItem("error", JSON.stringify(e));
        logout({ returnTo: logouRedirect });
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard/home" />;
  }

  localStorage.removeItem(TOKEN_KEY);
  return <Navigate to="/login" />;
}

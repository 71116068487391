import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { fetchCustom } from "../utils/fetch";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Top 10 users addition (3 months)",
    },
  },
};
const options1 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Top 10 repos PR (1 month)",
    },
  },
};

export default function DashBoard() {
  const [usersData, setUsersData] = useState(null);
  const [pullsData, setPullsData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getUsersTop = async () => {
      const resp = await fetchCustom(
        `${process.env.REACT_APP_API_SERVER_URL}/usersTop`,
        getAccessTokenSilently
      );
      if (resp) {
        // build data
        const labels = resp.map((u) => ({
          label: u.name || u.login,
          additions: u.additions,
          deletions: u.deletions,
        }));
        const data = {
          labels: labels.map((item) => item.label),
          datasets: [
            {
              label: "additions",
              data: labels.map((item) => item.additions),
              backgroundColor: "#3fb950",
            },
            {
              label: "deletions",
              data: labels.map((item) => item.deletions),
              backgroundColor: "#ce202f",
            },
          ],
        };
        setUsersData(data);
      }
    };

    const getTopPullrequest = async () => {
      const resp = await fetchCustom(
        `${process.env.REACT_APP_API_SERVER_URL}/getTopPullrequest`,
        getAccessTokenSilently
      );

      if (resp) {
        const labels = resp.map((p) => ({
          label: p.name,
          count: p.count,
        }));
        const data = {
          labels: labels.map((p) => p.label),
          datasets: [
            {
              label: "PullRequests",
              data: labels.map((p) => p.count),
              backgroundColor: "#3fb950",
            },
          ],
        };
        setPullsData(data);
      }
    };
    getUsersTop();
    getTopPullrequest();
  }, [getAccessTokenSilently]);

  if (!usersData || !pullsData) {
    return (
      <div>
        <h1>Loading ...</h1>
      </div>
    );
  }

  // thông tin user thêm xoá code
  // số lượng PR dc tạo ra thêm của Repo trong 1 tháng
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={8}>
        <Bar options={options} data={usersData} />
      </Grid>
      <Grid item xs={8}>
        <Bar options={options1} data={pullsData} />
      </Grid>
    </Grid>
  );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    if (!Array.isArray(array)) {
        return []
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_user) => _user.login.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function formatReviewsData(array, isGerrit) {
    let newArrray = [];
    array.forEach(item => {
        if (!item.body) {
            item.body = "No Content"
        }
        if (!item.state) {
            item.state = "COMMENTED"
        }
        let reponame = isGerrit ? item.repo.name : item.repo.full_name
        Object.assign(item, {
            pull_title: item.pull.title,
            pull_html_url: item.pull.html_url,
            repository_url: item.repo.html_url,
            repository_name: reponame
        });
        newArrray.push(item);
    })
    return newArrray;
}

function formatPullRequestsData(array, isGerrit) {
    let newArrray = [];
    array.forEach(item => {
        Object.assign(item, {
            repository_url: item.repo.html_url,
            repository_name: isGerrit ? item.repo.name : item.repo.full_name
        });
        newArrray.push(item);
        
    })
    return newArrray;
}


export { descendingComparator, getComparator, applySortFilter, formatReviewsData, formatPullRequestsData }
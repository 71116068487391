import {
  Card,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { applySortFilter, getComparator } from '../utils/sortData';
import { fToNow, upperFirst } from './../utils/formatTime';
import { useEffect, useState } from 'react';

// components
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/user';
import { fetchCustom } from '../utils/fetch';
import { useAuth0 } from '@auth0/auth0-react';

const TABLE_HEAD_REPOS = [
  { id: 'name', label: 'Name', alignRight: false, sort: false },
  { id: 'data_type', label: 'Data Type', alignRight: false, sort: false },
  { id: '', label: 'Description', alignRight: false, sort: false },
  { id: 'pushed_at', label: 'Pushed at', alignRight: false, sort: true },
];

export default function Repos(prop) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('pushed_at');
  const [filterName] = useState('');
  const [repos, setRepos] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchRepos = async () => {
      const data = await fetchCustom(
        `${process.env.REACT_APP_API_SERVER_URL}/repos`,
        getAccessTokenSilently,
      );
      if (data) {
        setRepos(data);
      }
    };
    fetchRepos();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    if (property) setOrderBy(property);
  };

  let filteredUsers = applySortFilter(
    repos,
    getComparator(order, orderBy),
    filterName,
  );
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Repositories">
      <Container>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Repositories
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Total: {repos.length}
          </Typography>
        </Stack>
        <Card>
          <TableContainer sx={{ minWidth: 800, minHeight: 400 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD_REPOS}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {filteredUsers.map((row) => {
                  const {
                    id,
                    name,
                    html_url,
                    description,
                    pushed_at,
                    data_type,
                  } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="2">
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={html_url}
                          variant="body2"
                        >
                          {name}
                        </Link>
                      </TableCell>
                      <TableCell style={{ width: '20%' }} align="left">
                        {upperFirst(data_type)}
                      </TableCell>
                      <TableCell align="left">{description}</TableCell>
                      <TableCell style={{ width: '20%' }} align="left">
                        {fToNow(pushed_at)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery="" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}

// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/home',
    icon: getIcon('material-symbols:dashboard'),
    gerrit: false
  },
  {
    title: 'user',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
    gerrit: false
  },
  {
    title: 'Repositories',
    path: '/dashboard/repositories',
    icon: getIcon('eva:shopping-bag-fill'),
    gerrit: false
  }
];

const navGRConfig = [
  {
    title: 'Users',
    path: '/dashboard/gerrit/users',
    icon: getIcon('eva:people-fill'),
    gerrit: true
  },
  {
    title: 'Repositories',
    path: '/dashboard/gerrit/repositories',
    icon: getIcon('eva:shopping-bag-fill'),
    gerrit: false
  }
];

export { navConfig, navGRConfig };

import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserListHead } from "../sections/user";
import { applySortFilter, getComparator } from "../utils/sortData";
import { useEffect, useState } from "react";

import Page from "../components/Page";
import SearchNotFound from "../components/SearchNotFound";
import { fToNow } from "./../utils/formatTime";
import { fetchCustom } from "../utils/fetch";
import { useAuth0 } from "@auth0/auth0-react";

const TABLE_HEAD = [
  // { id: '', label: '', alignRight: false, sort: false },
  { id: "login", label: "Username", alignRight: false, sort: true },
  { id: "name", label: "Name", alignRight: false, sort: true },
  { id: "additions", label: "Code Change", alignRight: false, sort: false },
  {
    id: "last_activity",
    label: "Last Activity",
    alignRight: false,
    sort: false,
  },
];

export default function User(prop) {
  const navigate = useNavigate();

  const [order, setOrder] = useState("desc");

  const [orderBy, setOrderBy] = useState("last_activity");

  const [filterName] = useState("");
  //   const filteredUsers =
  const [users, setUsers] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await fetchCustom(
        `${process.env.REACT_APP_API_SERVER_URL}/users`,
        getAccessTokenSilently
      );
      if (data) {
        setUsers(data);
      }
    };
    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );
  const isUserNotFound = filteredUsers.length === 0;

  const gotoUserPullRequest = (user) => {
    let navURL = "/dashboard/users/pullrequest";
    navigate(navURL, { state: user });
  };

  return (
    <Page title="Users">
      <Container>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Total: {users.length}
          </Typography>
        </Stack>
        <Card>
          <TableContainer sx={{ minWidth: 800, minHeight: 400 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {filteredUsers.map((row, index) => {
                  const {
                    id,
                    avatar_url,
                    login,
                    last_activity,
                    name,
                    additions,
                    deletions,
                  } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                      <TableCell
                        style={{ width: "30%" }}
                        component="th"
                        scope="row"
                      >
                        <Stack
                          component={Button}
                          onClick={() => gotoUserPullRequest(row)}
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          <Avatar alt={login} src={avatar_url} />
                          <Typography variant="subtitle2" noWrap>
                            {login}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left" style={{ width: "25%" }}>
                        {name}
                      </TableCell>
                      <TableCell align="left" style={{ width: "25%" }}>
                        {`+${additions} / -${deletions}`}
                      </TableCell>
                      <TableCell align="left" style={{ width: "25%" }}>
                        {fToNow(last_activity)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery="" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}

import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  if (!date) {
    return 'Long time ago';
  }
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function upperFirst(str) {
  return `${str}`.charAt(0) + `${str}`.toLowerCase().substring(1);
}

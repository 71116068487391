import { TOKEN_KEY } from "./constant";

export async function fetchCustom(url, func, options) {
  let token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    token = await getToken(func);
  }
  try {
    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: options?.method || "get",
    });
    return await resp.json();
  } catch (error) {
    localStorage.removeItem(TOKEN_KEY);
  }
  return null;
}

export async function getToken(func) {
  const accessToken = await func({
    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
    scope: "read:current_user",
  });
  localStorage.setItem(TOKEN_KEY, accessToken);
  return accessToken;
}
